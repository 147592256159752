import React from "react";
import logo from "./logo.png";
import "./App.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const RED_PINK = "#E00074";
export const FIRE_ORANGE = "#ff2b2b";
export const SOAP_BLUE = "#01dacc";
export const MOSS_GREEN = "#00E06C";
export const SIMPLE_BLUE = "#1C8BFF";
export const STRAIGHT_PURPLE = "#853ef7";
export const BOLD_YELLOW = "#ffb400";
export const SUBTLE_RED = "#FF2BFD";

export const HABIT_CONFIG = {
  habit_one: {
    description: "Worked Out?",
    label: "1+ hr",
    icon: "fas fa-heartbeat",
    color: RED_PINK,
    position: "top-right-right"
  },
  habit_two: {
    description: "Ate clean?",
    label: "Ate clean",
    icon: "fas fa-utensils",
    color: FIRE_ORANGE,
    position: "bottom-left-left"
  },
  habit_three: {
    description: "Drank 3+ L Water?",
    label: "3+ liters",
    icon: "far fa-glass",
    color: SIMPLE_BLUE,
    position: "top-right-top"
  },
  habit_four: {
    description: "Spent $0?",
    label: "$0",
    icon: "fas fa-dollar-sign",
    color: MOSS_GREEN,
    position: "bottom-left-bottom"
  },
  habit_five: {
    description: "Learned 3 new things today",
    label: "3+ things learned",
    icon: "far fa-lightbulb-on",
    color: BOLD_YELLOW,
    position: "bottom-right-bottom"
  },
  habit_six: {
    description: "Meditated 15+ minutes?",
    label: "15+ min",
    icon: "fas fa-om",
    color: SUBTLE_RED,
    position: "top-left-top"
  },
  habit_seven: {
    description: "Slept 7+ hours?",
    label: "7+ hours ",
    icon: "far fa-snooze",
    color: SOAP_BLUE,
    position: "bottom-right-right"
  },
  habit_eight: {
    description: "Read for 30+ minutes?",
    label: "30+ min",
    icon: "fas fa-book",
    color: STRAIGHT_PURPLE,
    position: "top-left-left"
  }
};

const App: React.FC = () => {
  const matches = useMediaQuery("(max-width:480px)");
  return (
    <div className="App">
      <header className="App-header">
        <div
          style={{
            display: "flex",
            flexDirection: matches ? "column" : "row",
            alignItems: "center"
          }}
        >
          <div
            className="px-2"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <img src={logo} className="App-logo" alt="logo" />
            <div className="d-flex flex-row mb-4">
              {Object.values(HABIT_CONFIG).map(habit => {
                return (
                  <i
                    key={habit.label}
                    style={{ fontSize: "2em", color: habit.color }}
                    className={`${habit.icon} mx-2`}
                  />
                );
              })}
            </div>
            <p className="px-2 mt-4">
              All your <b style={{ color: "black" }}>habits</b>. One{" "}
              <b style={{ color: "black" }}>square</b> per day.
              <br /> Track what’s important and improve over time. <br />
              Start improving every day, today. <br />
              <b>
                <a
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: 600
                  }}
                  href="http://www.app.habitsquare.com"
                >
                  Go to app
                </a>
              </b>
              {/* <i
                style={{
                  marginLeft: ".5rem",
                  color: "black"
                }}
                className="fab fa-apple"
              />
              <i
                style={{
                  marginLeft: ".5rem",
                  color: "black"
                }}
                className="fab fa-android"
              />
              <i
                style={{
                  marginLeft: ".5rem",
                  color: "black"
                }}
                className="fab fa-chrome"
              /> */}
            </p>
          </div>
          {/* <div style={{ display: "flex", alignItems: "center" }}>
            <video playsinline height={matches ? 400 : 800} autoPlay muted loop>
              <source src="demo.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div> */}
        </div>
      </header>
    </div>
  );
};

export default App;
